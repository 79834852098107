import { Notice, QueuePosition } from "bouncer-waitingroom";

export class WaitingRoomHandler {
    // dynamic waiting room elements
    private positionInfoElement: HTMLParagraphElement;

    // notices
    private noticeListElement: HTMLUListElement;

    // enter button
    private enterButtonElement: HTMLButtonElement;

    //body
    private bodyElement: HTMLBodyElement;

    constructor() {
        this.positionInfoElement = document.querySelector("#position-info") as HTMLParagraphElement;

        this.noticeListElement = document.querySelector("#notice-list") as HTMLUListElement;

        this.enterButtonElement = document.querySelector("#enter") as HTMLButtonElement;

        this.bodyElement = document.querySelector(".background-container") as HTMLBodyElement;

        this.registerHandlers();
    }


    private registerHandlers(): void {
        document.addEventListener("bouncer:queuePositionUpdated", (e) => this.updateQueuePosition(e.detail));
        document.addEventListener("bouncer:noticesUpdated", (e) => this.updateNotices(e.detail));
    }

    private updateQueuePosition(queuePosition: QueuePosition | null): void {
        this.updateProgressText(queuePosition);
        this.changeBackground(queuePosition);
    }

    private updateProgressText(queuePosition: QueuePosition | null): void {
        if (queuePosition === null) {
            this.positionInfoElement.innerText = "Du bist im Vorwarteraum. Deine Position wird Dir später angezeigt!"
            return;
        }

        if (queuePosition.position === 1) {
            console.info("one ticket ahead")

            this.positionInfoElement.innerText = "Ein Fan ist vor Dir!"

            return;
        }

        if (queuePosition.position <= 0) {
            console.info("its this tickets turn")

            this.positionInfoElement.innerText = "Du bist jetzt dran!"
            this.enterButtonElement.disabled = false;

            return;
        }

        this.enterButtonElement.disabled = true;
        this.positionInfoElement.innerText = `${queuePosition.position} Fans sind vor Dir!`;
    }

    private changeBackground(queuePosition: QueuePosition | null): void {
        const isQueueing = queuePosition !== null;
        if (isQueueing) {
            this.bodyElement.classList.remove("background-container__prequeueing");
            this.bodyElement.classList.add("background-container__queueing");
        } else {
            this.bodyElement.classList.remove("background-container__queueing");
            this.bodyElement.classList.add("background-container__prequeueing");
        }
    }

    private updateNotices(notices: Array<Notice>): void {
        let updatedNoticeList = "";
        notices.forEach(n => updatedNoticeList += `<li class="list-group-item">${n.message}</li>`);

        this.noticeListElement.innerHTML = updatedNoticeList;
    }
}
